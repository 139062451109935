.botBar {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.17);
  padding: 0 12px;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.btn,
.btn:focus {
  color: rgba(255, 255, 255, 0.17) !important;
}

.btn:hover {
  color: rgba(255, 255, 255, 0.47) !important;
}
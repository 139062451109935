html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

html {
  background-color: #242424;
}

body {
  background-color: transparent !important;
  color: #fff;
}
.topBar {
  width: 100%;
  height: 64px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  z-index: 1;
  position: relative;
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-variant: small-caps;
  margin: 0 10px;
}

.role {
  font-size: 14px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 64px;
  right: 0;
  bottom: 32px;
  left: 0;
  z-index: 1;
}

.container .ant-row {
  justify-content: center;
}

.content {
  padding: 0 10px;
  font-size: 32px;
  font-variant: small-caps;
  text-shadow: 0px 0px 4px #000;
  color: #fff;
  text-align: center;
}

.techContainer {
  margin-top: 10px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
}

.techContainer svg {
  margin: 3px 0;
}